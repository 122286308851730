.custom-input input[type="number"]::-webkit-inner-spin-button,
.custom-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input-number-no-spinner::-webkit-inner-spin-button,
.input-number-no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-number-no-spinner {
  -moz-appearance: textfield; 
}

.table_address{
  font-family: inherit;
  margin: 0px;
}