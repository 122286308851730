.table_main_view{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  /* border: 0.1px solid; */
}
.table_coloums{
  width: 85%;
} 
.table_col_1{
  width: 85%;
  padding: 5px;
  border: 0.1px solid;
}
.table_col_2{
  width: 150px;
  border: 0.1px solid;
}
.export_icons{
display: flex;
justify-content: flex-end;
/* margin-right: 20px; */
}
.print_content{
/* display: none; */
}
.product_back_button{
  font-size: 18px;
  cursor: pointer;
}
.product_back_button :hover{
  color: #4EA144;
}
