.show_product_page .ant-page-header-heading-title{
    color: #4EA144 !important;
}
.show_product_page .Title_headings{
    color: #4EA144;
}
.show_product_page .ant-space-item{
    display: none;
}
.show_product_page .ant-modal-footer{
    display: none;
}
.show_product_page .product_back_button{
    font-size: 25px;
}
.show_product_page .product_back_button :hover{
    color: #4EA144;
}