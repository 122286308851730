.input-save {
    float: right;
    background-color: #4ea144;
    border: 1px solid #d0d7e1;;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    padding: 3px 10px;
    text-align: center;
    text-decoration: none; 
    display: inline-block;
    font-size: 16px;
    border-width: 0.5px;
    border-radius: 2px;
}

.input-save:hover {
    border-color: #4EA144;
    color: #fff;
}
.profile_page .category_img {
    width: 150px;
    height: 100px;
}
.profile_page .Image_div {
    display: flex;
    justify-content: space-between;
}
.profile_page .category_img_div {
    padding-left: 10px;
}