.Create_Category.ant-modal   {
    width: 550px !important;
} 
.Edit_category.ant-modal   {
    width: 550px !important;
} 
.Create_Category .category_img {
    width: 150px;
    height: 100px;
}
.Create_Category .Image_div {
    display: flex;
    justify-content: space-between;
}
.Create_Category .category_img_div {
    padding-left: 10px;
}
.Edit_category .category_img {
    width: 150px;
    height: 100px;
}
.Edit_category .Image_div {
    display: flex;
    justify-content: space-between;
}
.Edit_category .category_img_div {
    padding-left: 10px;
}