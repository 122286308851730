.upload-label {
    padding: 8px 16px;
    background-color: #e0e0e0;
    color: #000;
    cursor: pointer;
  }
  
  .upload-label:hover {
    background-color: #4EA144;
    color: aliceblue;
  }