.create_banner .category_img{
   width: 80px;
   height: 70px;
}
.edit_banner .category_img{
   width: 80px;
   height: 70px;
}
.create_banner .heading{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}
.create_banner .description{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}
.create_banner .button{
   display: grid;
   grid-template-columns: 2.1fr 2.1fr 1fr 1fr;
   grid-gap: 16px;
}
.create_banner .others{
   display: grid;
   grid-template-columns: 2.1fr 2.1fr 1fr 1fr;
   grid-gap: 16px;
}
.create_banner .banner_props{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}
.edit_banner .heading{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}
.edit_banner .description{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}
.edit_banner .button{
   display: grid;
   grid-template-columns: 2.1fr 2.1fr 1fr 1fr;
   grid-gap: 16px;
}
.edit_banner .others{
   display: grid;
   grid-template-columns: 2.1fr 2.1fr 1fr 1fr;
   grid-gap: 16px;
}
.edit_banner .banner_props{
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 16px;
}