.dashboard-content{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.dashborad-box{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.card{
    width: 300px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid #4EA144;
    cursor: pointer;
}

.countup-value {
    font-size: 20px; /* Custom styling for the CountUp value */
    font-weight: bold;
    color: #4EA144;
}

/* analysis */

.analysis_select .ant-select-selector{
    border:1px solid #4EA144 !important;
}

.analysis_select{
    width: 20%;
}

.analysis_card{
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.analysis_card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.card_action{
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.report_card{
    /* width: 200px; */
    flex: 1;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    border: 1px solid #4EA144;
    cursor: pointer;
}

.report_card .report_title{
    font-size: 14px;
    color: gray;
    font-weight: 500;

}

.report_body .report_box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.report_card p{
    margin: 0px;
}

.analysis_report_prductInsig_box{
    display: flex;
    gap: 5px;
}

.report_card_prductInsig{
    flex:  1;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    border: 1px solid #4EA144;
    cursor: pointer;
}

.report_body{
    display: flex;
}

.analysis_report_box{
    display: flex;
    flex: 1;
    gap: 10px;
}

.report_value_section{
    display: flex;
    gap: 10px;
    align-items: center;
}

.report_value_section p {
    margin: 0px;
}

.arrow{
    transform: rotate(42deg);
    font-size: 20px;
    color: #4EA144;
}

.report_rate{
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin: 0px;
}

.analysis_chart{
    margin-top: 10px;
}

.countup_count{
    font-size: 18px;
    font-weight: 500;
}

 /* legend */


 .legend_pip_continer{
    display: flex;
    justify-content: center;
 }

 .legend_pip_box{
    display: flex;
    flex-direction: column;
   align-items: flex-start;
 }

 .indicator_color{

 }

.legend_box{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.legend_card{
    border-radius:5px ;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.legend_card_body{
    margin: auto;
    margin: 5px;
}

.legend_indicator{
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 10px;
}

.legend_pip_indicator{
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.legend_pip_indicator .indicator_color{
    padding: 6px;
    border-radius: 50%;
    height: 2px;
    display: block;
    width: 10px;
}

.legend_pip_indicator .indicator_txt{
    margin: 0px;
}

.indicator_color{
    padding: 1px;
    height: 2px;
    display: block;
    width: 10px;
}

.legend_indicator p{
    margin: 0px;
}

.indicator_txt{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.custom-tooltip_continer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 
    0px 1px 0px 0px #1A1A1A12, /* First shadow */
    0px 1px 0px 0px #CCCCCC80 inset, /* Second shadow */
    0px -1px 0px 0px #0000002B inset, /* Third shadow */
    -1px 0px 0px 0px #00000021 inset, /* Fourth shadow */
    1px 0px 0px 0px #00000021 inset; /* Fifth shadow */
    opacity: 0.8;
}

.tooltip_header p{
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
}

.tooltip_body{
    display: flex;
    flex-direction: column;
    gap:5px
}

.tooltip_indicator{
    padding: 1px;
    height: 2px;
    display: block;
    width: 10px;
}

.tooltip_indicator-lable{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0px;
}

.tooltip_content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.tooltip_indicator-box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.top_sale_card_wrapper{
    display: flex;
    gap: 20px;
}

.top_sales_tba{
    padding: 20px;
    border-radius: 16px;
    width: 80%;
    margin-top: 20px;
    box-shadow: 
    0px 1px 0px 0px #1A1A1A12, /* First shadow */
    0px 1px 0px 0px #CCCCCC80 inset, /* Second shadow */
    0px -1px 0px 0px #0000002B inset, /* Third shadow */
    -1px 0px 0px 0px #00000021 inset, /* Fourth shadow */
    1px 0px 0px 0px #00000021 inset; /* Fifth shadow */
    opacity: 0.8;
}

.stacked_bar_card{
    padding: 20px;
    border-radius: 16px;
    width: 50%;
    height: 500px;
    margin-top: 20px;
    box-shadow: 
    0px 1px 0px 0px #1A1A1A12, /* First shadow */
    0px 1px 0px 0px #CCCCCC80 inset, /* Second shadow */
    0px -1px 0px 0px #0000002B inset, /* Third shadow */
    -1px 0px 0px 0px #00000021 inset, /* Fourth shadow */
    1px 0px 0px 0px #00000021 inset; /* Fifth shadow */
    opacity: 0.8;
}

.analysis_table{
    display: flex;
    justify-content: center;
}

.analysis_tsp_table{
    margin-top: 20px;
    padding: 16px;
    width: 100%;
    border-radius: 16px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
