.header_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.header_container_box_1{
    width: 60%;
}
.header_container_box_2{
    width: 40%;
}
.invoice_total_value_table{
    margin-bottom: 10px;
}
.invoice_total_content{
    min-width: 140px;
}
.invoice_total_content_value{
    font-size: 15px;
    font-weight: 400;
    padding: 10px;
}
.invoice_items_table{
    height: 340px;
    overflow-y: scroll;
}
.invoice_icons{
    display: flex;
    justify-content: end;
    gap: 15px;
}

.invoice_icons div:nth-child(1){
    display: flex;
    align-items: center;
}

.invoice_icons div:nth-child(2){
    display: flex;
    align-items: center;
}

.fav_Print{
    cursor: pointer;
}
.invoice_print_page{
    margin: 0px 20px 0px 20px;
}
.invoice_heading_container{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.invoice_heading_text{
    font-size: 25px;
}
.invoice_heading_image{
    height: 70px;
    width: 70px;
    /* border-radius: 50%; */
}
.invoice_detail_container{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.ourcompany_details_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.ourcomany_details{
    width: 35%;
}
.ourcomany_details_heading{
    text-transform: uppercase;
}
.customercompany_details{
    width: 35%;
}
.invoice_details_box{
    width: 30%;
}
.invoice_table_container{
    margin-top: 30px;
}
.invoice_totalvalues_container{
    margin-top: 30px;
}
.invoice_totalvalues_box{
    display: flex;
    margin-right: 8%;
}
.invoice_totalvalues_emptybox{
    width: 70%;
}
.invoice_totalvalue_text{
    width: 25%;
    /* text-align: right; */
}
.invoice_totalvalue_value{
    width: 10%;
    text-align: right;
}
.invoice_notes_container{
    margin-top: 30px;
}
.invoice_terms_container{
    margin-top: 60px;
}